<template>
    <!-- <el-dialog :visible.sync="departAdds" width="55%" title="指定科室" :close-on-click-modal='false' @close="closeadddepart"> -->
    <q-dialog v-model="departAdds" persistent @hide='closeadddepart' position="top">
        <q-card style="min-width: 550px">
            <q-card-section class="q-pt-none"></q-card-section>
          <q-card-section class="q-pt-none">
            <div class='departtop'>
              <q-form ref="appointdepart" greedy class="q-gutter-md add">
                <!-- 省份选择 -->
                <selectp @provincechange='provincechange' class='add' :options='addqueryDatas.province' :model.sync='addqueryDatas.provinceid' :label='"请选择省份*"' />
                <!-- 城市选择 -->
                <selectc @citychange='citychange' class='add' :options='addqueryDatas.city' :model.sync='addqueryDatas.cityId' :label='"请选择城市*"'/>
                <!-- 医院选择 -->
                <selecth @hospchange='hospchange' class='add' :options='addqueryDatas.hosp' :model.sync='addqueryDatas.hospid' :label='"请选择医院*"'/>
                <!-- 科室多选 -->
                <q-select
                    v-model="addqueryDatas.depid"
                    multiple
                    :options="addqueryDatas.dep"
                    option-label="departName"
                    label="请选择科室"
                    :dense="true"
                    outlined
                    lazy-rules  
                    options-selected-class="text-deep-orange"
                    :rules="[
                        required.selectds
                    ]"
                />
                <!-- <el-select v-model="addqueryDatas.depid" multiple placeholder="请选择科室" style='width: 100%;'>
                    <el-option
                    v-for="item in addqueryDatas.dep"
                    :key="item.id"
                    :label="item.departName"
                    :value="item.id">
                    </el-option>
                </el-select> -->
              </q-form>
            </div>
            <!-- <div class='departtop'>
              <q-input outlined :dense="true" v-model="departDesc" label="请输入科室描述" />
            </div> -->
          </q-card-section>

          <q-card-actions align="right">
            <q-btn class="kong" flat label="取消" color="primary" v-close-popup />
            <q-btn label="绑定" color="primary" @click='okadd'/>
          </q-card-actions>
        </q-card>
      </q-dialog>
      <!-- </el-dialog> -->
</template>
<script>
import selectp from '@/components/Order/select/provinceselect.vue' // 省份下拉框组件
import selectc from '@/components/Order/select/cityselect.vue' // 城市下拉框组件
import selecth from '@/components/Order/select/hospselect.vue' // 医院下拉框组件
import  {required}  from '@/api/components/required.js' // 表单验证规则
import { getprovince,getcity,gethosplist,getdepart,getcombo } from '@/api/components/getCity.js'; // 获取城市接口


export default {
    props:{
        departAdd: { //控制指定医院
            type: Boolean,
            required: true,
        },
        addqueryData: { //所需的城市储存 和 id储存
            type: Object,
            required: true,
        },
        // appoint: { //控制指定医院
        //     type: Object,
        //     required: true,
        // },
    },
    data() {
        return {
            required

        }
    },
    components:{
        selectp,
        selectc,
        selecth,
    },
    created(){
    },
    computed:{
        departAdds:{
            get(){
                return this.departAdd
            },
            set(v){
                this.$emit('update:departAdd',v)
            }
        },
        // appointdata:{
        //     get(){
        //         return this.appoint
        //     },
        //     set(v){
        //         this.$emit('update:appoint',v)
        //     }
        // },
        addqueryDatas:{
            get(){
                return this.addqueryData
            },
            set(v){
                this.$emit('update:addqueryData',v)
            }
        },
    },
    methods:{
        provincechange(){
            this.addqueryDatas.cityId=''
            this.addqueryDatas.city=[]//清空城市
            this.addqueryDatas.hospid=''
            this.addqueryDatas.hosp=[]//清空医院
            this.addqueryDatas.dep=[] //清空科室
            this.addqueryDatas.depid =[]
            console.log(this.addqueryDatas.provinceid);
            getcity(this.addqueryDatas.city,this.addqueryDatas.provinceid.id)
        },
            // 城市变化时获取医院列表
        citychange(){
            this.addqueryDatas.hosp=[]
            this.addqueryDatas.hospid=''
            this.addqueryDatas.dep=[] //清空科室
            this.addqueryDatas.depid =[]
            gethosplist(this.addqueryDatas.hosp,this.addqueryDatas.cityId.id) //获取医院信息用于检索
            console.log(this.addqueryDatas.hosp);
        },
        // 医院发生变化时
        hospchange(){
            this.addqueryDatas.dep=[] //清空科室
            this.addqueryDatas.depid =[]
            getdepart(this.addqueryDatas.dep,this.addqueryDatas.hospid.id)
            console.log(this.addqueryDatas.dep);
        },
        // 点击绑定时
        okadd(){
            this.$emit('okadd');
            console.log(this.addqueryDatas.depid);
        },
        //弹窗关闭时
        closeadddepart(){
            this.$emit('closeadddepart')
        }
        
    },
}
</script>
<style lang="less" scoped>

</style>
