<template>
  <div id="users" class="q-pa-md q-gutter-sm">
    <!-- 面包屑 -->
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="iconfont icon-zhuye" label="主页" to="/orm/dashboard" />
      <q-breadcrumbs-el label="权限管理" icon="iconfont icon-quanxian" />
      <q-breadcrumbs-el label="用户管理" icon="iconfont icon-yonghu1" to="/user/list" />
    </q-breadcrumbs>
    <!-- 总卡片区 -->
    <div class="row" id="userBox">
      <div class="col-2 q-pa-md treeBox" :style='`max-height: ${tableHeight}px;`'>
        <!-- <q-card flat bordered class="my-card">
          <q-card-section> -->
              <div class="row justify-between">
                <q-input ref="filter" outlined :dense='true' v-model="searchGroup" label="输入用户组名称搜索">
                  <template v-slot:append>
                    <q-icon v-if="searchGroup !== ''" name="clear" class="cursor-pointer" @click="resetFilter" />
                  </template>
                </q-input>
              </div>
              <!-- 用户组列表 -->
              <q-tree 
              :nodes="Group" 
              no-connectors
              selected-color="primary" 
              @update:selected='changeselected' 
              :selected.sync="selected" 
              label-key="groupName" 
              no-nodes-label='暂无数据'
              no-results-label='暂无数据'
              node-key="id" 
              :filter='searchGroup'
              >
                <template v-slot:default-header="prop">
                    <div class="row items-center cursor-pointer">
                        {{ prop.node.groupName }}
                    </div>
                </template>
              </q-tree>
          <!-- </q-card-section>
        </q-card> -->
      </div>
      <div class="col-10 q-pa-md">
        <q-table
          :style='`max-height: ${tableHeight}px;`'
          class="usersTable"
          title="Treats"
          :data="userlist"
          :columns="columns"
          :pagination="pagination"
          :hide-pagination="true"
          row-key="name"
        >
        
        <template v-slot:top>
          <q-input outlined :dense='true' v-model="queryData.userName" label="用户名/用户昵称" />
          <q-input style='margin-left: 5px;' outlined :dense='true' type="number" v-model="queryData.phoneNumber" label="手机号" />
          <q-btn :disable='Permissionslist.filter(item=>item==="user.list").length>0?false:true' class='operation' color="blue-6" label="重置" @click="chongzhi" />
          <q-btn :disable='Permissionslist.filter(item=>item==="user.list").length>0?false:true' class='operation' color="blue-6" label="搜索" @click="serachuser" />
          
          <q-space />
          <!-- <q-select v-model="model" :options="options" label="状态" /> -->
          <q-btn :disable='Permissionslist.filter(item=>item==="user.add").length>0?false:true' color="blue-6" label="新增用户" @click="xingzeng" />
        </template>
          <!-- 按钮操作区 -->
          <!-- <template v-slot:body-cell-phoneNumber="mobile">
            <q-td :props="mobile">
              {{mobile.row.phoneNumber===''?'空':mobile.row.phoneNumber}}
            </q-td>
          </template> -->
          <template v-slot:body-cell-operation="anniu">
            <q-td :props="anniu">
              <q-btn 
                :disable='(Permissionslist.filter(item=>item==="user.edit").length>0) && anniu.row.userName!="admin"?false:true'
                class="czbtn"
                size="sm"
                color="blue"
                icon="iconfont icon-Edit"
                @click="editusers(anniu.row)"
               >
               </q-btn>
              <q-btn
                :disable='(Permissionslist.filter(item=>item==="user.delete").length>0) && anniu.row.userName!="admin"?false:true'
                class="czbtn"
                size="sm"
                color="red-6"
                icon="iconfont icon-ashbin"
                @click="deluser(anniu.row)"
              >
              </q-btn>
              <!-- 旧绑定医院 -->
              <!-- <q-btn
                :disable='anniu.row.userName!="admin"?false:true'
                class="czbtn"
                size="sm"
                color="yellow-7"
                icon="iconfont icon-yiyuan"
                @click="hospbind(anniu.row)"
              ></q-btn> -->
              <q-btn
                :disable='anniu.row.userName!="admin"?false:true'
                class="assignbtn"
                size="sm"
                color="yellow-7"
                icon="iconfont icon-yiyuan"
                @click="departsbind(anniu.row)"
              ></q-btn>
              <!-- <q-btn :disable='anniu.row.isAdmin ? true : false' flat color="primary" label="分配角色" @click="allocationroles(anniu.row)" ></q-btn> -->
              <!-- <q-btn :disable="progress" flat color="primary" :label="anniu.row.state===0 ?'启用':'禁用'" ></q-btn> -->
              <!-- <q-btn flat color="primary" label="重置密码" ></q-btn> -->
            </q-td>
          </template>
          <template v-slot:bottom>
            <elementPagination :total='total' :queryData='queryData'  @changepageSize='changepageSize' @changePage='changePage'/>
          </template>
          <!-- 无数据时显示 -->
          <template v-slot:no-data="">
            <div class="full-width row flex-center text-dark q-gutter-sm my-special-class">
              <span style="color: #909399;font-size: 14px;">
                暂无数据
              </span>
            </div>
          </template>
        </q-table>
        
          
      </div>
    </div>

    <!-- 新增用户对话框组件 -->
    <el-dialog :visible.sync="addconfirm" width="35%" title="新增用户" @close="dialogclose" :close-on-click-modal='false'>
      <useradd 
      ref='adduser'
      :addusers='addusers'
      :confirm='addconfirm'
      :options='options'
      :Group='Group'
      :bindqueryData.sync='bindqueryData'
      @editconfirm='dialogclose()'
      @addusersuccess='getuserlist()'
      @closedialog='addconfirm = false'
      />
    </el-dialog>
    <!-- 编辑用户对话框组件 -->
    <el-dialog :visible.sync="editconfirm" title="编辑用户" :close-on-click-modal='false' width="35%" @close='editclose'>
      <useredit 
        ref='edituser'
        :editfrom='editfrom' 
        :Group='Group'
        :options='options'
        :Codelibrary.sync='Codelibrary'
        :userRoles.sync='userRoles'
        :userGroup.sync='userGroup'
        :AllGroupName.sync='AllGroupName'
        :AllGroup='AllGroup'
        :bindqueryData.sync='bindqueryData'
        @editconfirm='editclose()' 
        @editusersuccess='getuserlist()' 
        @tickeddian='tickedchange()'
        @closeeditdialog='editconfirm = false'
      />
    </el-dialog>

    <!-- 绑定科室 -->
    <userdepart
      ref="userdepart"
      :depconfirm.sync='depconfirm'
      :bindqueryData.sync='bindqueryData'
      :bindData='bindData'
    />
    <!-- 查看用户信息对话框组件 -->
    <!-- <q-dialog v-model="checkconfirm">
      <usercheck :checkfrom='checkfrom' :assignRoles='assignRoles' />
    </q-dialog> -->
    <!-- 分配角色对话框组件 -->
    <!-- <q-dialog v-model="roleconfirm" persistent>
      <allocationroles :rolesfrom='rolesfrom' @closeallocationdialog='roleconfirm = false'/>
    </q-dialog> -->

  </div>
  
</template>

<script>
import { getItem } from '@/utils/storage.js';
import jwt from 'jsonwebtoken'
import useradd from '@/components/User/Useradd.vue' // 添加用户组件
import useredit from '@/components/User/Useredit.vue' // 修改用户组件
import userdepart from '@/components/User/Userdepart.vue' // 修改用户组件
import elementPagination from '@/components/ElementUI/elementPagination.vue' // 分页组件
import { makeDebounce } from "@/utils/debounce.js";

// import usercheck from '@/components/User/Usercheck.vue' // 查看用户组件
import { UserList,DelUser,ViewUser,getRoleList,getallhosp,getuserhosp,userdeplist } from '@/api/User.js'; // 获取接口
import { Grouplist } from '@/api/Group.js'; // 获取接口
import { provinceList } from '@/api/City.js'; // 获取城市接口
import { getcity,gethosplist,getdepart,getoptions } from '@/api/components/checkcitys.js'; // 获取城市接口

import { mapState } from "vuex"

import { getHeight } from '@/api/getHeight.js'; // 获取列表最高高度
import { notifys } from '@/api/components/Notify.js'

export default {
  data () {
    return {
      // 列表最大高度
      tableHeight:200,
      //分页设置 
      pagination: {
        page: 1,
        rowsPerPage: 20, //设置默认是显示多少条,
      },
      total: 0,
      // 获取用户列表
      queryData: {
        userName: '',
        phoneNumber:'',
        // 当前页码
        page: 1,
        // 每页显示多少条
        pageSize: 10,
        groupId: '' // 用户组id
      },
      // 密码验证正则表达
      reg: new RegExp(/^(?=.*\d)(?=.*[a-zA-Z]).{8,20}$/),
      // 新增用户信息
      addusers:{
        userName:'',
        nickName:'',
        password:'',
        confirmpassword: '',
        phoneNumber: '',
        verifyCode:'',
        userType: '', // 控制是否为管理员
        countdown:60, //新增验证码倒计时
        timeClock: null, //空的倒计时器
        timeconfirm: true, //控制是否显示倒计时
        // adminis: null, //控制是否可以选择管理员
      },
      
      // 编辑用户修改密码信息
      Codelibrary:{
        newPwd: '',
        confirmnewPwd: '',
        verifyCode: '', //编辑用户的验证码信息
        phone:'', //储存用户手机号
        verifyCodeconfirm: true, //控制验证码能否获取
        countdown: 60, //倒计时
        timeClock: null, //空的倒计时器
        timeconfirm: true, //控制是否显示倒计时
      },

      AllGroup:[], //平铺所有用户组信息
      Group: [], // 用户组列表信息
      AllGroupName:[], //所选用户组名称  
      searchGroup: '', // 用于搜索用户组
      selected: null, // 所点击的用户组信息
      selectedid:'', //储存所点击用户组的id
      
      // useid: '',
      // Allhosp: [],
      // userbospid:[],
      // filterText:'',

      roleslist:{}, //角色选项信息
      editfrom: {}, // 修改用户信息储存
      options:[], // 角色列表信息

      userRoles:[], //用户所绑定的角色
      userGroup:[], //用户所绑定的用户组

      roleconfirm: false, // 控制分配角色对话框
      editconfirm: false, // 控制编辑用户弹框
      addconfirm: false,// 控制新增用户弹框
      hospconfirm: false, // 控制绑定医院弹框
      depconfirm: false, // 控制绑定科室弹窗
      // 列表头部信息
      columns: [
        
        {
          name: 'userName',
          required: true,
          label: '用户名',
          align: 'left',
          field: row => row.userName,
          format: val => `${val}`,
          headerClasses: 'my-special-class',
        },
        { name: 'nickName',align: 'center', label: '用户昵称', field: 'nickName',headerClasses: 'my-special-class' },
        { name: 'phoneNumber',align: 'center', label: '手机号', field: 'phoneNumber',headerClasses: 'my-special-class'},
        { name: 'createTime',align: 'center', label: '创建时间', field: 'createTime',headerClasses: 'my-special-class'},
        // { name: 'state',align: 'center', label: '状态', field: 'state'},
        { name: 'operation',align: 'center', label: '操作', field: 'operation',headerClasses: 'my-special-class'}
      ],
      // 表格内容信息
      userlist: [],

      bindData:{}, //绑定科室所需信息
      bindqueryData:{
        alldep:[], //储存科室列表数据

        hospid:'', //医院 .id
        cityId:'', //城市 .id
        provinceid:'', //省份 .id
        deperid:'', //新增编辑用户时医生用户所需科室id
        depid:[], // 用户绑定多个科室时 id 储存数组
        
        province:[], // 指定医院时 省份数据
        city:[], // 指定医院时 城市数据
        hosp: [], // 指定医院时医院数据
        dep:[], // 所有科室数据
      },
      
    }
  },
  created(){
    this.getuserlist() // 获取用户列表
    this.getGroupList() // 获取全部用户组信息
    this.getrolelist() // 获取全部角色信息
    this.getprovince() // 获取所有省份
  },
  watch: {},
  computed: {
    ...mapState([
      'Permissionslist' // 权限词典
    ])
  },
  components:{
    useradd,
    useredit,
    elementPagination,
    // usercheck,
    userdepart, //绑定科室
  },
  methods:{

    // 获取省份信息
    async getprovince(){
      const { data:res } = await provinceList()
      console.log(res);
      this.bindqueryData.province = res.data.provinces
    },
    // 获取角色信息
    async getrolelist(){
      const { data: res } = await getRoleList()
      this.options = res.data.roles
    },

    // 点击重置
    chongzhi:makeDebounce(function(){
      this.queryData.userName=''
      this.queryData.phoneNumber=''
      this.selected = null
      this.selectedid =''
      this.getuserlist()
    },200),
    // 点击搜索
    serachuser:makeDebounce(function(){
      // this.selectedid =''
      this.queryData.page=1
      this.getuserlist()
    },200),

    // 获取用户组列表
    async getGroupList(){
      const { data: res } = await Grouplist()
      this.Group = res.data.groups
      this.treeToArray(this.Group)
    },
    // 利用递归函数 将树状资料全部平铺
    treeToArray (tree) {
      let arr= [];
      const expanded = datas => {
          if (datas&& datas.length > 0){
              datas.forEach(e => {
                  arr.push(e);
                  expanded(e.children);
              })
          }
      };
      expanded(tree);
      return this.AllGroup=arr;
    },


    // 获取用户列表
    async getuserlist(){
      console.log(this.selectedid);
      const {data: res} = await UserList({
        userName:this.queryData.userName,
        phoneNumber:this.queryData.phoneNumber,
        groupId: this.selectedid,
        page:this.queryData.page,
        pageSize: this.queryData.pageSize,
      })
      console.log(res);
      this.total = res.data.totals *1
      this.userlist = res.data.users

      let height = 50
      this.tableHeight = getHeight('userBox',height)
      console.log(this.tableHeight);
    },
    xingzeng(){
      // const token = getItem('token')
      // console.log(jwt.decode(token));
      // this.addusers.adminis=jwt.decode(token).isAdmin
      // console.log(this.addusers.adminis);
      this.addconfirm = true
    },
    // 当用户组被点击时 当selected发生更新时 target就是获取到的node-key值
    changeselected(target){
      console.log(target);
      this.queryData.page=1
      this.selectedid = target
      this.getuserlist()
      // console.log(target);
    },

    //, 编辑用户信息
    editusers:makeDebounce( async function(row){
      const {data: res} = await ViewUser({id:row.id})
      console.log(res);
      if(res.code===20000){
        this.editfrom = res.data.user
        this.Codelibrary.phone = res.data.user.phoneNumber
        this.Codelibrary.verifyCodeconfirm=true

        // 如果是医生获取 省城医科
        if(this.editfrom.userType == 4){
          // 如果四个id都有就计算
          if(this.editfrom.provinceId && this.editfrom.cityId && this.editfrom.hospId && this.editfrom.departId){
            this.bindqueryData.city= await getcity(this.editfrom.provinceId) // 获取所有城市
            this.bindqueryData.hosp=await gethosplist(this.editfrom.cityId) //获取所有医院
            this.bindqueryData.dep=await getdepart(this.editfrom.hospId) //获取所有科室


            // 获取省份所选项
            this.bindqueryData.provinceid = getoptions(this.bindqueryData.province,this.editfrom.provinceId);
            // 获取城市所选项
            this.bindqueryData.cityId = getoptions(this.bindqueryData.city,this.editfrom.cityId)
            //获取所选医院
            this.bindqueryData.hospid=getoptions(this.bindqueryData.hosp,this.editfrom.hospId) 
            // 获取所选科室
            this.bindqueryData.deperid=getoptions(this.bindqueryData.dep,this.editfrom.departId) 
          }
        }
        console.log(this.editfrom);
        this.userRoles =this.editfrom.roleIds
        this.userGroup = this.editfrom.groupIds
        const tickeds = this.userGroup
        const AllGroups = this.AllGroup
        this.AllGroupName=[] //清空所选用户组名称  

        if(AllGroups!=null && tickeds!=null){
          tickeds.map(id=>{
            AllGroups.map(Gname=>{
                if(id===Gname.id){
                  this.AllGroupName.push(Gname.groupName)
                }
            })
          })
        }
      }
      this.editconfirm = true
    },300),
    
    tickedchange(){
      this.AllGroupName=[]
      const tickeds = this.userGroup
      const AllGroups = this.AllGroup
        tickeds.map(id=>{
            AllGroups.map(Gname=>{
                if(id===Gname.id){
                  this.AllGroupName.push(Gname.groupName)
                }
            })
        })
        console.log(this.AllGroupName);
        console.log(this.userGroup);
    },


    // 删除用户
    deluser(userid){
      console.log(userid);
      this.$q.dialog({
        title: '删除',
        message: `你确定要删除用户:${userid.userName}吗?`,
        ok: { label: '确定',color: 'red'},
        cancel: { label: '取消',flat: true},
      }).onOk(async() => {
        try{
            const {data:res} = await DelUser({id:userid.id})
            console.log(res);
            if(res.code===20000){
              this.getuserlist()
              notifys('positive',res.message)
            }else{
              notifys('negative',res.message)
            }
          }catch (e){
              console.log(e);
          }
      })
    },
   
    // 用户科室绑定
    async departsbind(row){
      console.log(row);
      const {data: res} = await ViewUser({id:row.id})
      if(res.code===20000){
        const { data: depdata } = await userdeplist({id: row.id})
        if(depdata.code==20000){
          this.bindqueryData.alldep = depdata.data.departs
          console.log(this.bindqueryData.alldep);
          this.bindData = res.data.user
          this.depconfirm = true
        }
      }
    },
    // 新增窗口关闭时  
    dialogclose(){
      this.$refs.adduser.$refs.myForm.resetValidation()
      this.addconfirm = false
      this.addusers.userName='',
      this.addusers.nickName='',
      this.addusers.password='',
      this.addusers.confirmpassword= '',
      this.addusers.phoneNumber= '',
      this.addusers.verifyCode= '',
      this.addusers.timeconfirm=true
      this.addusers.countdown=60
      this.addusers.userType=''

      // 清空省份城市医院科室
      this.bindqueryData.city = []
      this.bindqueryData.hosp = []
      this.bindqueryData.dep = []
    
      this.bindqueryData.hospid = ''
      this.bindqueryData.cityId = ''
      this.bindqueryData.provinceid = ''
      this.bindqueryData.deperid = ''

      clearInterval(this.addusers.timeClock);
    },
    // 关闭编辑对话框时
    editclose(){
      this.$refs.edituser.$refs.myForm.resetValidation()
      this.editconfirm= false
      this.Codelibrary.newPwd=''
      this.Codelibrary.confirmnewPwd=''
      this.Codelibrary.verifyCode=''
      this.Codelibrary.timeconfirm=true
      this.Codelibrary.countdown=60

      // 清空省份城市医院科室
      this.bindqueryData.city = []
      this.bindqueryData.hosp = []
      this.bindqueryData.dep = []
    
      this.bindqueryData.hospid = ''
      this.bindqueryData.cityId = ''
      this.bindqueryData.provinceid = ''
      this.bindqueryData.deperid = ''

      clearInterval(this.Codelibrary.timeClock);
    },
    // 清空搜索用户组
    resetFilter(){
      this.searchGroup=''
      this.$refs.filter.focus()
    },
    // 分页发送变化函数
    changepageSize(val){
      console.log(val);
      this.queryData.pageSize = val
      this.pagination.rowsPerPage = val
      this.getuserlist()
    },
    changePage(val){
      console.log(val);
      this.queryData.page = val
      console.log(this.queryData.page);
      this.getuserlist()
    }
    // 点击分页按钮时触发
    // onRequest (props) {
    //   // console.log(props);
    //   const { page, rowsPerPage, sortBy, descending } = props.pagination
    //   this.pagination.page = page  // 点击之后改变页码值 传给this.pagination.page
    //   this.queryData.pageSize = rowsPerPage // 一页多少条改变是触发 获取数据给queryData.pageSize
    //   this.pagination.rowsPerPage = rowsPerPage
    //   this.getuserlist() // 重新渲染页面
    // }
  }
}
</script>

<style scoped lang='less'>
  #users{
    height: 100%;
    overflow: hidden;
    #userBox{
      height: 100%;
    }
    .treeBox{
      overflow: auto;
    }
  }
  /deep/.el-dialog__body{
    padding: 15px 20px !important;
  }
  .search{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 1.25rem;
    .searchbox{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .searchtest{
        width: 15.625rem;
        margin-left: .9375rem;
        margin-right: .9375rem;
      }  
    }
  }

  .my-table-details {
    font-size: 0.85em;
    font-style: italic;
    max-width: 500px;
    white-space: normal;
    color: #555;
    margin-top: 4px;
  }
  .operation{
    margin-right: .625rem;
    margin-left: .625rem;
  }
  .userCard{
    width: 100%;
    max-width: 350px
  }
  .control{
    margin-right: .3125rem;
  }
  .Pagination{
    display: flex;
    justify-content: flex-end;
    margin-top: .625rem;
  }
  .czbtn{
    margin-right: .75rem;
  }
  /deep/.my-special-class{
    font-size: 14px;
    font-weight: bold;
    color: #909399;
  }
</style>