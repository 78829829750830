<template>
    <div>
        <q-form ref="myForm" class="q-gutter-md edit">
          <div class='maxgao'>
            <div class="row justify-between">
              <div class="col-5">
                <q-input
                  ref="phone"
                  class="edit"
                  mask="###########"
                  :dense="true"
                  outlined
                  v-model="Codelibrarys.phone"
                  label="手机号*"
                  lazy-rules
                  @input='phonechange'
                  :rules="[required.phonelength]"
                />
              
                <q-input
                  class="edit"
                  :type="isPwd ? 'password' : 'text'"
                  ref="input"
                  :dense="true"
                  outlined
                  v-model="Codelibrarys.newPwd"
                  label="新密码*"
                  lazy-rules
                  :rules="[required.defaults]"
                >
                  <template v-slot:append>
                      <q-icon
                          :name="isPwd ? 'visibility_off' : 'visibility'"
                          class="cursor-pointer"
                          @click="isPwd = !isPwd"
                      />
                  </template>
                </q-input> 
              </div>
              <div class="col-5">
                <q-input
                  :disable='Codelibrarys.verifyCodeconfirm'
                  mask="######"
                  class="add"
                  :dense='true'
                  outlined
                  v-model="Codelibrarys.verifyCode"
                  label="验证码*"
                  lazy-rules
                  :rules="[required.editverifyCode]"
                >
                    <template v-slot:append>
                      <q-btn v-if="Codelibrarys.timeconfirm" :disable='Codelibrarys.verifyCodeconfirm' size="xs" flat color="primary" label="获取验证码" dense @click="getsmsCode"/>
                      <span v-else style="font-size: 13px;" class="clock">{{Codelibrarys.countdown}}S</span>
                    </template>
                </q-input>
                
                <q-input
                  class="edit"
                  :type="isPwd2 ? 'password' : 'text'"
                  :dense="true"
                  outlined
                  v-model="Codelibrarys.confirmnewPwd"
                  label="确认新密码*"
                  lazy-rules
                  :rules="[val => val.trim()===this.$refs.input.value || '两次密码不一致']"
                >
                  <template v-slot:append>
                      <q-icon
                        :name="isPwd2 ? 'visibility_off' : 'visibility'"
                        class="cursor-pointer"
                        @click="isPwd2 = !isPwd2"
                      />
                  </template>
                </q-input> 
              </div>
            </div>

            <transition-group name="lyric"> 
              <!-- 省份城市医院科室 -->
              <div v-if="editfrom.userType == 4" class="row justify-between" :key="1">   
                <div class="col-5">
                  <!-- 省份选择 -->
                  <selectp @provincechange='provincechange' class='add' :options='bindqueryDatas.province' :model.sync='bindqueryDatas.provinceid' :label='"请选择省份*"' />
                  <!-- 医院选择 -->
                  <selecth @hospchange='hospchange' class='add' :options='bindqueryDatas.hosp' :model.sync='bindqueryDatas.hospid' :label='"请选择医院*"'/>
                </div>
                <div class="col-5">
                  <!-- 城市选择 -->
                  <selectc @citychange='citychange' class='add' :options='bindqueryDatas.city' :model.sync='bindqueryDatas.cityId' :label='"请选择城市*"'/>
                  <!-- 科室选择 -->
                  <selectd class='add' :options='bindqueryDatas.dep' :model.sync='bindqueryDatas.deperid' :label='"请选择科室*"'/>
                </div>
              </div>
            </transition-group>
            
            <!-- 昵称,选择用户组 -->
            <div style='margin-top: 0;margin-bottom: 20px;'>
              <!-- 昵称 -->
              <q-input
                class="edit"
                :dense="true"
                outlined
                v-model="editfrom.nickName"
                label="昵称*"
                lazy-rules
                :rules="[required.kong,required.nicklength]"
              />
              <!-- 选择用户组 -->
              <q-select
                outlined 
                :dense="true"
                v-model="AllGroupName"
                label="选择用户组"
                style="width: 100%;"
              >
                  <template v-slot:no-option>
                  <q-item>
                      <q-item-section class="select">
                          <q-tree
                              class="col-12 col-sm-6"
                              :nodes="Group"
                              node-key="id"
                              label-key="groupName"
                              no-connectors
                              tick-strategy="strict"
                              :ticked.sync="userGroupModel"
                              :accordion="true"
                              @update:ticked='tickeddian'
                              ref="tree"
                          ></q-tree>
                      </q-item-section>
                  </q-item>
                  </template>
              </q-select>
            </div>
            <!-- 请选择角色 -->
            <div style='margin-bottom: 20px;'>
              <el-select
                v-model="userRolesModel"
                multiple
                placeholder="请选择角色"
                style="width: 100%"
              >
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.roleName"
                    :value="item.id"
                  >
                  </el-option>
              </el-select>
            </div>
            <!-- 请选择用户类型 -->
            <div style='margin-bottom: 20px;'>
              <el-select v-model="editfrom.userType" placeholder="请选择用户类型" style='width: 100%;'>
                <el-option
                  v-for="item in userType"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </div>
            
          </div>
          
          <div class="editbtn">
              <q-btn
                flat
                label="取消"
                color="primary"
                v-close-popup
                @click="quxiao"
              />
              <q-btn label="保存" color="blue-6" @click="edituser" />
          </div>
        </q-form>
    </div>
</template>

<script>
import { mapState } from "vuex"; //vuex获取数据
import { Grouplist } from '@/api/Group.js'; // 获取用户组接口
import  {required}  from '@/api/components/required.js' // 表单验证规则
import { EditUser,getSmsCode } from "@/api/User.js";

import selectp from '@/components/Order/select/provinceselect.vue' // 省份下拉框组件
import selectc from '@/components/Order/select/cityselect.vue' // 城市下拉框组件
import selecth from '@/components/Order/select/hospselect.vue' // 医院下拉框组件
import selectd from '@/components/Order/select/departsselect.vue' // 科室下拉框组件
import { getcity,gethosplist,getdepart } from '@/api/components/checkcitys.js'; // 获取城市接口
import { notifys } from '@/api/components/Notify.js'

export default {
    created() {
      this.treeToArray(this.Group)
    },
    // 接收的父组件信息
    props: {
        editfrom: {
          type: Object,
          required: true,
        },
        options: {
          //角色信息
          type: Array,
          required: true,
        },
        Codelibrary: {
          //新密码和确认密码
          type: Object,
          required: true,
        },
        userRoles: {
          //用户绑定的角色id
          type: Array,
        },
        userGroup: {
          // 用户绑定的用户组id
          type: Array,
        },
        Group:{ // 所有用户组数据
          type: Array,
          required: true,
        },
        AllGroupName:{ //用户所绑定的用户组名称
          type: Array,
        },
        // 绑定 省城医科
        bindqueryData:{
          type: Object,
          required: true,
        },
    },
    data() {
        return {
            required,
            // 密码验证正则表达
            reg: new RegExp(/^(?=.*\d)(?=.*[a-zA-Z]).{8,20}$/),
            // rules 规则
            time: true,
            // 级联选择器的配置对象
            cascaderProps: {
              multiple: true,
              checkStrictly: true,
              expandTrigger: "hover",
              label: "groupName",
              value: "id",
            },
            isPwd: true,
            isPwd2: true,

            model: [], // 储存角色id  两个model都是包含着所选项的所有信息并不是只有id 只需要吧所有信息存入model就可以有默认值
            modelGroup: [], //储存用户组id

            modelGroups: [],
            AllGroup:[], //所有用户组平铺数据
            // ticked:[...this.userGroup]
        };
    },
    components: {
      selectp, // 省份下拉框组件,
      selectc, //城市下拉框
      selecth, // 医院下拉框
      selectd, // 科室下拉框
    },
    computed: {
      ...mapState([
        "userType", //绑定用户类型
      ]),
      // 让角色id与父组件形成双向绑定
      userRolesModel: {
        get() {
          return this.userRoles
        },
        set(v) {
          this.$emit("update:userRoles", v)
        }
      },
      // 让用户组id与父组件形成双向绑定
      userGroupModel:{
        get(){
          return this.userGroup
        },
        set(v){
          this.$emit('update:userGroup', v)
        }
      },
      // 让绑定的用户组名称 和父组件形成双向绑定
      userGroupName:{
        get(){
          return this.AllGroupName
        },
        set(v){
          this.$emit('update:AllGroupName', v)
        }
      },
      Codelibrarys:{
        get(){
          return this.Codelibrary
        },
        set(v){
          this.$emit('update:Codelibrary', v)
        }
      },
      bindqueryDatas:{
        get(){
          return this.bindqueryData
        },
        set(v){
          this.$emit('update:bindqueryData', v)
        }
      },
    },
    watch: {},
    methods: {  
      async provincechange(){
        this.bindqueryDatas.cityId=''
        this.bindqueryDatas.city=[]//清空城市
        this.bindqueryDatas.hospid=''
        this.bindqueryDatas.hosp=[]//清空医院
        this.bindqueryDatas.dep=[] //清空科室
        this.bindqueryDatas.deperid =''
        
        // 如果有省份id
        if(this.bindqueryDatas.provinceid){
          this.bindqueryDatas.city = await getcity(this.bindqueryDatas.provinceid.id)
        }
      },
      // 城市变化时获取医院列表
      async citychange(){
        this.bindqueryDatas.hosp=[]
        this.bindqueryDatas.hospid=''
        this.bindqueryDatas.dep=[] //清空科室
        this.bindqueryDatas.deperid =''
        // 如果有城市id
        if(this.bindqueryDatas.cityId){
          this.bindqueryDatas.hosp = await gethosplist(this.bindqueryDatas.cityId.id)
        }
      },
      // 医院发生变化时
      async hospchange(){
        this.bindqueryDatas.dep=[] //清空科室
        this.bindqueryDatas.deperid =''
        // 如果有医院id
        if(this.bindqueryDatas.hospid){
          this.bindqueryDatas.dep = await getdepart(this.bindqueryDatas.hospid.id)
        }
      },

      // 获取用户组列表
      async getGroupList(){
        const { data: res } = await Grouplist()
        this.Group = res.data.groups
        this.ticked = this.userGroup
         // 将获取到的数据平铺
        // console.log(this.AllGroup); // 平铺后的数据
      },
      // 手机号发生改变时
      phonechange(){  
        if(this.Codelibrarys.phone!=this.editfrom.phoneNumber){
          this.Codelibrarys.verifyCodeconfirm= false
        }else{
          this.Codelibrarys.verifyCodeconfirm= true
        }
      },
      // 触发倒计时
      sendCode() {
        var timer_num = 60;
        this.Codelibrarys.timeClock=setInterval(()=>{
            timer_num--;
            this.Codelibrarys.countdown = timer_num
          
            if (timer_num == 0) {
              clearInterval(this.Codelibrarys.timeClock);
              this.Codelibrarys.timeconfirm=true
              this.Codelibrarys.countdown=60
            } 
        },1000)
      },
      // 点击获取验证码
      async getsmsCode(){
        this.Codelibrarys.timeconfirm = false
        const { data: res } = await getSmsCode({phoneNumber:this.Codelibrarys.phone})
        if(res.code===20000){
          notifys('positive',res.message)
        }else{
          notifys('negative',res.message)
        }
        this.sendCode()
      },
      // 点击修改
      edituser() {
        this.$refs.myForm.validate().then( async outcome =>{
          if(outcome){
              const provinceId =  this.editfrom.userType == 4 ? this.bindqueryDatas.provinceid.id : ''
              const cityId =  this.editfrom.userType == 4 ? this.bindqueryDatas.cityId.id : ''
              const hospId =  this.editfrom.userType == 4 ? this.bindqueryDatas.hospid.id : ''
              const departId =  this.editfrom.userType == 4 ? this.bindqueryDatas.deperid.id : ''
              try {
                const { data: res } = await EditUser({
                  id: this.editfrom.id,
                  nickName: this.editfrom.nickName,
                  userType: this.editfrom.userType,
                  phoneNumber: this.Codelibrarys.phone,
                  password: this.Codelibrarys.newPwd,
                  verifyCode: this.Codelibrarys.verifyCode,
                  roleIds: this.userRolesModel,
                  groupIds: this.userGroup,
                  provinceId,
                  cityId,
                  hospId,
                  departId,
                });
                console.log(res);
                if (res.code === 20000) {
                  notifys('positive',res.message)
                  this.$emit("editusersuccess"); // 表格刷新页面
                  this.$emit("closeeditdialog"); // 关闭修改对话框
                  this.modelGroups = []
                  console.log(data);
                  ;
                } else {
                  notifys('negative',res.message)
                }
            } catch (e) {}
          }
        })
      },
      // 点击取消
      quxiao() {
        const usersGroups = this.userGroupModel
        console.log(usersGroups);
        this.$refs.myForm.resetValidation();
        this.$emit("editconfirm");
      },
      // 当用户组选择框发生改变时
      // groupchange(v){
      //   console.log(v);
      //   this.$emit('groupchange',v)
      // },
      // 用户组选择发生变化时
      tickeddian(){
        this.$emit('tickeddian')
      },
      // 利用递归函数 将树状资料全部平铺
      treeToArray (tree) {
        let arr= [];
        const expanded = datas => {
            if (datas&& datas.length > 0){
                datas.forEach(e => {
                    arr.push(e);
                    expanded(e.children);
                })
            }
        };
        expanded(tree);
        return this.AllGroup=arr;
      }
    },
};
</script>

<style lang='less' scoped>
// .edituser {
//   width: 21.875rem;
//   .edit {
//     width: 100%;
//     margin-left: 0;
//     margin-top: 0.1875rem;
//     .editbtn {
//         display: flex;
//         justify-content: flex-end;
//     }
//   }
// }
.maxgao{
  max-height: 50vh;
  overflow: auto;
  overflow-x: hidden;
}
.select{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.editbtn{
    display: flex;
    justify-content: flex-end;
}
/deep/.el-input__inner{
    border: 1px solid rgba(0, 0, 0, 0.24);
}
.lyric-enter,
.lyric-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
.lyric-enter-to,
.lyric-leave {
  opacity: 1;
}
.lyric-enter-active,
.lyric-leave-active {
  transition: all 0.5s;
}
</style>
