<template>
  <div>
    <q-form ref="myForm" greedy class="q-gutter-md add">
        <div class='maxgao'>
            <div class="row justify-between">   
                <div class="col-5">
                    <q-input class="add" :dense='true' outlined  v-model="addusers.userName" label="用户名*" lazy-rules :rules="[required.username,required.usernamelegth]"/>
                    <q-input mask="###########" class="add" :dense='true' outlined  v-model="addusers.phoneNumber" label="手机号*" lazy-rules :rules="[required.phoneNumber,required.phonelength]"/>
                    <!-- 密码框 -->
                    <q-input
                        class="add"
                        :type="isPwd ? 'password' : 'text'"
                        ref="input" 
                        :dense='true' 
                        outlined  
                        v-model="addusers.password" 
                        label="密码*"
                        lazy-rules 
                        :rules="[required.newpassword,required.password]"
                    >
                        <template v-slot:append>
                            <q-icon
                                :name="isPwd ? 'visibility_off' : 'visibility'"
                                class="cursor-pointer"
                                @click="isPwd = !isPwd"
                            />
                        </template>
                    </q-input>
                
                </div>
                <div class="col-5">
                    <q-input style='margin-top: 0;' class="add" :dense='true' outlined  v-model="addusers.nickName" label="昵称" lazy-rules :rules="[required.kong,required.nicklength]"/>
                    <q-input
                        mask="######"
                        class="add"
                        :dense='true'
                        outlined
                        v-model="addusers.verifyCode"
                        label="验证码"
                        lazy-rules='ondemand'
                        :rules="[required.verifyCode,required.verifyCodelength]"
                    >
                        <template v-slot:append>
                            <q-btn
                                v-if="addusers.timeconfirm"
                                :disable='addusers.phoneNumber.length==11?false:true'
                                size="xs"
                                flat color="primary"
                                label="获取验证码"
                                dense @click="getsmsCode"
                            />
                            <span v-else style="font-size: 13px;" class="clock">{{addusers.countdown}}S</span>
                        </template>
                    </q-input>
                    <!-- 确认密码框 -->
                    <q-input
                        class="add"
                        :type="isPwd2 ? 'password' : 'text'"
                        :dense='true'
                        outlined
                        v-model="addusers.confirmpassword"
                        label="确认密码*"
                        lazy-rules :rules="[val => val.trim()===this.$refs.input.value || '两次密码不一致']"
                    >
                        <template v-slot:append>
                            <q-icon
                                :name="isPwd2 ? 'visibility_off' : 'visibility'"
                                class="cursor-pointer"
                                @click="isPwd2 = !isPwd2"
                            />
                        </template>
                    </q-input>
                </div>
            </div>

            <transition-group name="lyric">
                <!-- 省份城市医院科室 -->
                <div v-if="addusers.userType == 4" class="row justify-between" :key='1'>   
                    <div class="col-5">
                        <!-- 省份选择 -->
                        <selectp @provincechange='provincechange' class='add' :options='bindqueryDatas.province' :model.sync='bindqueryDatas.provinceid' :label='"请选择省份*"' />
                        <!-- 医院选择 -->
                        <selecth @hospchange='hospchange' class='add' :options='bindqueryDatas.hosp' :model.sync='bindqueryDatas.hospid' :label='"请选择医院*"'/>

                    </div>
                    <div class="col-5">
                        <!-- 城市选择 -->
                        <selectc @citychange='citychange' class='add' :options='bindqueryDatas.city' :model.sync='bindqueryDatas.cityId' :label='"请选择城市*"'/>

                        <!-- 科室选择 -->
                        <selectd class='add' :options='bindqueryDatas.dep' :model.sync='bindqueryDatas.deperid' :label='"请选择科室*"'/>

                    </div>
                </div>
            </transition-group>
           
            <!-- 选择用户组 -->
            <div style='margin-top: 0;'>
                <q-select
                    outlined 
                    :dense="true"
                    v-model="AllGroupName"
                    label="选择用户组"
                    style="width: 100%;"
                    :rules="[required.kong]"
                >
                    <template v-slot:no-option>
                    <q-item>
                        <q-item-section class="select">
                            <q-tree
                                class="col-12 col-sm-6"
                                :nodes="Group"
                                node-key="id"
                                label-key="groupName"
                                no-connectors
                                tick-strategy="strict"
                                :ticked.sync="ticked"
                                :accordion="true"
                                @update:ticked='tickeddian'
                                ref="tree"
                            ></q-tree>
                        </q-item-section>
                    </q-item>
                    </template>
                </q-select>
            </div>
            <!-- 选择角色 -->
            <div style='margin-top: 0;margin-bottom: 20px;'> 
                <el-select v-model="model" multiple placeholder="请选择角色" style='width: 100%;'>
                    <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.roleName"
                    :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <!-- 选择用户类型 -->
            <div style='margin-bottom: 20px;'> 
                <el-select v-model="addusers.userType" placeholder="请选择用户类型" style='width: 100%;' >
                    <el-option
                    v-for="item in userType"
                    :key="item.key"
                    :label="item.value"
                    :value="item.key">
                    </el-option>
                </el-select>
            </div>
            
        </div>

        <div class="addbtn">
            <!-- <q-btn flat label="取消" color="primary" v-close-popup  @click="quxiao"/> -->
            <el-popover
                placement="bottom"
                width="155"
                v-model="visible">
                <p>未保存的数据将被丢弃, 是否继续?</p>
                <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                <el-button type="primary" size="mini" @click="quxiao">确定</el-button>
                </div>
                <el-button  type="text" slot="reference">取消</el-button>
            </el-popover>
            <q-btn class="kong" label="添加" color="blue-6" @click="adduser"/>
        </div>
    </q-form>
  </div>
</template>

<script>
import { mapState } from "vuex"; //vuex获取数据
import { Grouplist } from '@/api/Group.js'; // 获取用户组接口
import  {required}  from '@/api/components/required.js' // 表单验证规则

import selectp from '@/components/Order/select/provinceselect.vue' // 省份下拉框组件
import selectc from '@/components/Order/select/cityselect.vue' // 城市下拉框组件
import selecth from '@/components/Order/select/hospselect.vue' // 医院下拉框组件
import selectd from '@/components/Order/select/departsselect.vue' // 科室下拉框组件
import { getcity,gethosplist,getdepart } from '@/api/components/checkcitys.js'; // 获取城市接口

import { AddUser,getSmsCode } from '@/api/User.js';
import { notifys } from '@/api/components/Notify.js'

export default {
  created(){
    this.getGroupList()
  },
  data() {
    return {
        visible: false,//控制取消提示

        required,
        isPwd: true, // 控制密码是否显示
        isPwd2: true, // 控制密码是否显示
        // 密码验证正则表达
        reg: new RegExp(/^(?=.*\d)(?=.*[a-zA-Z]).{8,20}$/),
        // rules 规则
        // required: { // passwords: val => this.reg.test(val) || '密码必须为8~20位并且包含数字英文和特殊字符',
        // },
        // 级联选择器的配置对象
        cascaderProps: {
            multiple: true,
            checkStrictly: true,
            expandTrigger: 'hover',
            label: 'groupName',
            value: 'id'
        },
        model:[], // 储存角色id  两个model都是包含着所选项的所有信息并不是只有id 只需要吧所有信息存入model就可以有默认值
        // modelGroup:[], //储存用户组id

        // modelGroups:[],

        Group:[], // 用户组总数据
        ticked: [], // 所选项
        AllGroup:[], //所有用户组平铺数据
        AllGroupName:[] //所选用户组名称
    };
  },
  props:{
    addusers:{
        type: Object,
        required: true
    },
    options:{ //角色信息
        type: Array,
        required: true,
    },
    bindqueryData:{
        type: Object,
        required: true,
    },
  },
  components:{
    selectp, // 省份下拉框组件,
    selectc, //城市下拉框
    selecth, // 医院下拉框
    selectd, // 科室下拉框
  },
  computed: {
    ...mapState([
      "userType", //绑定用户类型
    ]),
    addusertwo:{
        get(){
            return this.addusers
        },
        set(v){
            this.$emit('update:addusers', v)
        }
    },
    bindqueryDatas:{
        get(){
            return this.bindqueryData
        },
        set(v){
            this.$emit('update:bindqueryData', v)
        }
    },
  },
  watch:{
  },
  methods: {
    async provincechange(){
        this.bindqueryDatas.cityId=''
        this.bindqueryDatas.city=[]//清空城市
        this.bindqueryDatas.hospid=''
        this.bindqueryDatas.hosp=[]//清空医院
        this.bindqueryDatas.dep=[] //清空科室
        this.bindqueryDatas.deperid =''
        
        // 如果有省份id
        if(this.bindqueryDatas.provinceid){
            this.bindqueryDatas.city = await getcity(this.bindqueryDatas.provinceid.id)
        }
    },
        // 城市变化时获取医院列表
    async citychange(){
        this.bindqueryDatas.hosp=[]
        this.bindqueryDatas.hospid=''
        this.bindqueryDatas.dep=[] //清空科室
        this.bindqueryDatas.deperid =''
        // 如果有城市id
        if(this.bindqueryDatas.cityId){
            this.bindqueryDatas.hosp = await gethosplist(this.bindqueryDatas.cityId.id)
        }
    },
    // 医院发生变化时
    async hospchange(){
        this.bindqueryDatas.dep=[] //清空科室
        this.bindqueryDatas.deperid =''
        // 如果有医院id
        if(this.bindqueryDatas.hospid){
            this.bindqueryDatas.dep = await getdepart(this.bindqueryDatas.hospid.id)
        }
    },
    // 获取用户组列表
    async getGroupList(){
        const { data: res } = await Grouplist()
        this.Group = res.data.groups
        this.treeToArray(this.Group) // 将获取到的数据平铺
        console.log(this.AllGroup); // 平铺后的数据
        const tickeds = this.ticked
        const AllGroups = this.AllGroup
    },
    // 触发倒计时
    sendCode() {
        var timer_num = 60;
        this.addusertwo.timeClock=setInterval(()=>{
            timer_num--;
            this.addusertwo.countdown = timer_num
            
            if (timer_num == 0) {
                clearInterval(this.addusertwo.timeClock);
                this.addusertwo.timeconfirm=true
                this.addusertwo.countdown=60
            } 
        },1000)
    },
    // 点击获取验证码
    async getsmsCode(){
        this.addusertwo.timeconfirm = false
        const { data: res } = await getSmsCode({phoneNumber:this.addusers.phoneNumber})
        console.log(res);
        if(res.code===20000){
            notifys('positive',res.message)
        }else{
            notifys('negative',res.message)
        }
        this.sendCode()
    },
    // 添加用户
    async adduser(){
        const roleIds= this.model.map(row=>row)
        console.log(this.addusers.userType,);
        this.$refs.myForm.validate().then( async outcome => {
            if(outcome){
                const provinceId =  this.addusers.userType == 4 ? this.bindqueryDatas.provinceid.id : ''
                const cityId =  this.addusers.userType == 4 ? this.bindqueryDatas.cityId.id : ''
                const hospId =  this.addusers.userType == 4 ? this.bindqueryDatas.hospid.id : ''
                const departId =  this.addusers.userType == 4 ? this.bindqueryDatas.deperid.id : ''
                try{
                    const {data:res} =  await AddUser({
                        userName: this.addusers.userName,
                        nickName: this.addusers.nickName,
                        userType: this.addusers.userType,
                        phoneNumber: this.addusers.phoneNumber,
                        password: this.addusers.password,
                        verifyCode: this.addusers.verifyCode,
                        roleIds: roleIds,
                        groupIds: this.ticked,
                        provinceId,
                        cityId,
                        hospId,
                        departId,
                    })
                    // console.log(res);
                    if(res.code===20000){
                        this.ticked = []
                        this.AllGroupName=[]
                        this.model = []
                        this.modelGroup=[]
                        this.$emit('addusersuccess') // 表格刷新页面
                        this.$emit('editconfirm') // 清空表单内容
                        this.$emit('closedialog')
                        this.$refs.myForm.resetValidation()
                        notifys('positive',res.message)
                    }else{
                        notifys('negative',res.message)
                    }
                    
                }catch(e){
                    console.log(e);
                }
            }
        });
        // this.$refs.myForm.validate().then( async outcome =>{})
    },
    //关闭时对话框是触发
    quxiao(){
        console.log(this.modelGroup);
        // console.log(this.Group);
        this.modelGroup=[]
        this.model=[]
        this.AllGroupName=[]
        this.ticked=[]
        this.visible = false

        this.bindqueryDatas.city = []
        this.bindqueryDatas.hosp = []
        this.bindqueryDatas.dep = []
        
        this.bindqueryDatas.hospid = ''
        this.bindqueryDatas.cityId = ''
        this.bindqueryDatas.provinceid = ''
        this.bindqueryDatas.deperid = ''

        this.$refs.myForm.resetValidation()
        this.$emit('editconfirm')
    },
    tickeddian(){
        this.AllGroupName=[]
        const tickeds = this.ticked
        const AllGroups = this.AllGroup
        tickeds.map(id=>{
            AllGroups.map(Gname=>{
                if(id===Gname.id){
                    this.AllGroupName.push(Gname.groupName)
                }
            })
        })
        console.log(this.AllGroupName);
    },
    // 利用递归函数 将树状资料全部平铺
    treeToArray (tree) {
        let arr= [];
        const expanded = datas => {
            if (datas&& datas.length > 0){
                datas.forEach(e => {
                    arr.push(e);
                    expanded(e.children);
                })
            }
        };
        expanded(tree);
        return this.AllGroup=arr;
    }
  },
};
</script>

<style lang='less' scoped>
// .adduser{
//     width: 21.875rem;
//     .add{
//         width: 90%;
//         margin-left: 0;
//         margin-top: .1875rem;
//         .addbtn{
//             display: flex;
//             justify-content: flex-end;
//         }
        
//     }
// }
.maxgao{
    max-height: 50vh;
    overflow: auto;
    overflow-x: hidden;
}
.group{
    z-index:999;
}
.select{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.kong{
  margin-left: 1.25rem !important;
}
.addbtn{
    display: flex;
    justify-content: flex-end;
}
/deep/.el-input__inner{
    border: 1px solid rgba(0, 0, 0, 0.24);
}
.lyric-enter,
.lyric-leave-to {
  opacity: 0;
  transform: translateY(10px);
}
.lyric-enter-to,
.lyric-leave {
  opacity: 1;
}
.lyric-enter-active,
.lyric-leave-active {
  transition: all 0.5s;
}
</style>
