import { createAPI } from "../utils/request"

export const UserList = data => createAPI('/acl/user/list', 'get', data) //用户列表

export const AddUser = data => createAPI('/acl/user/create', 'post', data) //新增用户

export const DelUser = data => createAPI(`/acl/user/delete/${data.id}`, 'get', data) //删除用户

export const EditUser = data => createAPI('/acl/user/save/', 'post', data) //编辑用户信息

export const ViewUser = data => createAPI(`/acl/user/get/${data.id}`, 'get', data) //查看用户信息

export const Editpassword = data => createAPI('/acl/user/password/edit', 'post', data) //修改密码

export const Doallocationrole = data => createAPI(`/acl/user/doAssign/`, 'get', data) //用户绑定角色

export const getRoleList = data => createAPI(`/acl/user/role/list`, 'get', data) //获取当前用户可绑定角色

export const getSmsCode = data => createAPI(`/acl/user/smsCode`, 'get', data) //获取短信验证码

export const getallhosp = data => createAPI(`/base/province/hosp`, 'get', data) //获取所有医院信息

export const getuserhosp = data => createAPI(`/acl/user/hosp/list`, 'get', data) //获取用户所绑定的医院信息

export const userbindhosp = data => createAPI(`/acl/user/hosp/bind`, 'get', data) //用户绑定医院

export const userbinddep = data => createAPI(`/acl/user/depart/bind`, 'get', data) //用户绑定科室

export const userdeplist = data => createAPI(`/acl/user/depart/bindList`, 'get', data) //获取用户已经绑定科室

export const userunbinddep = data => createAPI(`/acl/user/depart/unbind`, 'get', data) //用户解绑科室







